































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiBroadcastPatientExcel,
  ApiBroadcastPatientExcelBefore,
  ApiBroadcastPatientList,
} from "@/apis";
import { breadcrumb } from "@/utils/breadcrumb";
import moment from "moment";

@Component({
  components: {},
})
export default class LivePatientIndex extends Vue {
  tableLoading = false;
  exportLoading = false;

  // 查询条件
  query: any = {
    patient_name: "",
    doctor_name: "",
    title: "",
    live_broadcast_date_end: "",
  };
  // 类型
  typeList: Array<any> = [
    { value: null, label: "全部" },
    { value: "101", label: "嘉医云诊室小程序" },
  ];
  moduleList: Array<any> = [
    { value: null, label: "全部" },
    { value: "100", label: "科普" },
    { value: "101", label: "视频" },
    { value: "102", label: "直播" },
  ];
  questionStatus = (status: number) => {
    switch (status) {
      case 0:
        return "待回复";
      case 1:
        return "已回复";
      default:
        return "-";
    }
  };
  questionColorStatus = (status: number) => {
    switch (status) {
      case 0:
        return "#FF8800";
      case 1:
        return "#00B469";
      default:
        return "#FF8800";
    }
  };
  diseaseJoin = (arr: any) => {
    if (!arr) return "-";
    try {
      const parsedDisease = JSON.parse(arr);
      if (Array.isArray(parsedDisease)) {
        return JSON.parse(arr).join(",");
      }
    } catch (error) {
      return arr;
    }

    return "-";
  };
  moduleStatus = (status: number) => {
    switch (status) {
      case 100:
        return "科普";
      case 101:
        return "视频";
      case 102:
        return "直播";
      default:
        return "-";
    }
  };

  //时间格式处理
  dateFormat = function (row: any) {
    if (row === undefined) {
      return "-";
    }

    const moment = require("moment");
    return moment(row).format("YYYY-MM-DD HH:mm:ss");
  };

  // 数据
  tableData: Array<any> = [];
  //表格分页器变量
  pagination = {
    //分页器当前页显示几条数据
    size: 10,
    //表格的数据总量
    total: 0,
    //当前是第几页
    page: 1,
  };

  dash(data: any) {
    if (data === null || data === "" || data === undefined) {
      data = "-";
    }
    return data;
  }
  mounted() {
    this.getBroadcastPatientList();
  }
  search() {
    this.$set(this.pagination, "page", 1);
    this.getBroadcastPatientList();
  }

  resetClick() {
    this.pagination.size = 10;
    this.pagination.page = 1;
    Object.keys(this.query).forEach((key: any) => {
      this.query[key] = null;
    });
    this.getBroadcastPatientList();
  }

  async getBroadcastPatientList() {
    const param = JSON.parse(JSON.stringify(this.query)) as any;

    param.cur_page = this.pagination.page;
    param.page_size = this.pagination.size;

    if (param.live_broadcast_date_end) {
      // 结束时间添加时间为23:59:59;
      param.live_broadcast_date_end =
        param.live_broadcast_date_end + (24 * 60 * 60 * 1000 - 1000);
    }

    ApiBroadcastPatientList(param).then((res) => {
      console.log(res.data, "=====data");
      this.tableData = res.data;
      this.pagination.total = res.page.total_size;
      this.tableLoading = false;
    });
  }
  //分页器改变页显示数触发函数
  handleSizeChange(val: any) {
    this.pagination.size = val;
    this.getBroadcastPatientList();
  }
  //分页器改变当前显示页触发函数
  handleCurrentChange(val: any) {
    this.pagination.page = val;
    this.getBroadcastPatientList();
  }

  async downloadButtonClick(row: any) {
    this.exportLoading = true;
    const param = JSON.parse(JSON.stringify(this.query)) as any;
    const data = await ApiBroadcastPatientExcelBefore(param);
    if (typeof data.success == "undefined") {
      const res = await ApiBroadcastPatientExcel(param);
      const objUrl = window.URL.createObjectURL(res);
      const a = document.createElement("a");
      a.download = `${this.dateFormat(new Date())}.xlsx`;
      document.body.appendChild(a);
      a.href = objUrl;
      a.click();
      a.remove();
      this.$message.success("文件下载中");
      this.exportLoading = false;
    } else {
      this.exportLoading = false;
    }
  }
  goDetail(row: any) {
    console.log(row);
    breadcrumb.push(
      this,
      "/livePatient/detail",
      "详情",
      { id: row.id, broadcast_question_id: row.broadcast_question_id },
      // { id: 9, broadcast_question_id: 6 },
      true
    );
  }
}
